import { ProductOrder } from "../types/typeOrders";

type ListOrdersProps = {
  children?: JSX.Element | JSX.Element[];
  url: string;
  product: ProductOrder;
};
export default function ListOrders({
  url,
  children,
  product,
}: ListOrdersProps) {
  return (
    <li className="bag-list__product">
      <img
        className="bag-list__product-foto"
        src={product.foto ? `${url}/${product.foto}` : "../images/images.png"}
        alt="фото товара"
      />
      <h2 className="bag-list__product-title">{product.title}</h2>
      <span className="bag-list__product-price">{`${product.price} руб.`}</span>
      {children}
    </li>
  );
}
