import ErrorSpan from "./ErrorSpan";
import BottonSubmit from "./buttons/ButtonSubmit";

type FormProps = {
  handleSubmit: (evt: React.FormEvent<HTMLFormElement>) => void;
  textButton: string;
  children: (JSX.Element | string)[];
  formRef?: React.LegacyRef<HTMLFormElement> | undefined;
  textErr?: string;
  disabledButton?: boolean;
};

export default function Form({
  handleSubmit,
  textButton,
  children,
  formRef,
  textErr,
  disabledButton,
}: FormProps) {
  return (
    <form
      ref={formRef}
      noValidate
      onSubmit={(evt) => handleSubmit(evt)}
      className="form"
    >
      {children}
      <BottonSubmit text={textButton} disabled={disabledButton} />
      <ErrorSpan text={textErr} />
    </form>
  );
}
