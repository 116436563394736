import { useSelector } from "react-redux";
import { selectAuth } from "../redax/slices/authSlice";
import { useEffect, useState } from "react";
import { OrdersRes } from "../types/typeOrders";
import { order } from "../utils/OrderApi";

import {
  ModulConfirmation,
  ListCardOrder,
  ButtonsNavigation,
  Search,
  ErrorBox,
  ErrorSpan,
} from "../components/index";

export default function OrdersArchive() {
  const { token } = useSelector(selectAuth);
  const [orders, isOrders] = useState<OrdersRes[]>();
  const [errArchive, isErrArchive] = useState<boolean | string>(false);
  const [confirmation, isConfirmation] = useState<boolean>(false);
  const [idProduct, isIdProduct] = useState<string>();
  const [resOrders, isResOrders] = useState<boolean>(false);
  const [errorDeleteText, isTextErrorDelete] = useState("");
  const [resOrdersErr, isResOrdersErr] = useState("");

  useEffect(() => {
    getOrders();
  }, []);

  const getOrders = () => {
    token &&
      order
        .getArchiveOrder(token)
        .then((res: OrdersRes[]) => {
          //console.log(res.reverse());
          isOrders(res.reverse());
        })
        .catch((err) => {
          //console.log(err);
          isOrders(undefined);
          isResOrdersErr(err.message);
        })
        .finally(() => isResOrders(true));
  };

  const deleteOrder = (id: string | undefined): void => {
    token &&
      id &&
      order
        .deleteOrder({ token, id })
        .then((res: OrdersRes[]) => {
          isOrders(orders?.filter((order) => order._id !== id));
        })
        .catch((err) => {
          isTextErrorDelete(`При удалении произошла ошибка: ${err.message}.`);
          isErrArchive(id);
          setTimeout(() => isErrArchive(false), 1500);
        });
  };

  const openConfirm = (id: string) => {
    isIdProduct(id);
    isConfirmation(true);
  };

  return (
    <div className="orders">
      <ButtonsNavigation page="/" text="На главную" />
      {resOrders ? (
        orders ? (
          orders.length > 0 ? (
            <ListCardOrder
              orders={orders}
              click={openConfirm}
              arr={errArchive}
              textButton="Удалить"
              errorText={errorDeleteText}
            />
          ) : (
            <ErrorBox>
              <ErrorSpan text="Архив пуст."></ErrorSpan>
            </ErrorBox>
          )
        ) : (
          <ErrorBox>
            <ErrorSpan
              text={`При загрузке данных произошла ошибка: ${resOrdersErr}.`}
            ></ErrorSpan>
          </ErrorBox>
        )
      ) : (
        <Search />
      )}
      {confirmation && (
        <ModulConfirmation
          text="Удалить?"
          confirm={() => deleteOrder(idProduct)}
          close={() => isConfirmation(false)}
        />
      )}
    </div>
  );
}
