import { useEffect, useState } from "react";
import { product } from "../utils/ProductApi";
import { useSelector } from "react-redux";
import { selectAuth } from "../redax/slices/authSlice";
import { useNavigate } from "react-router-dom";

import {
  ModulePreloader,
  ButtonsNavigation,
  Button,
  ErrorSpan,
  FotoAdd,
} from "../components/index";

export default function FormAddFotoProduct() {
  const navigate = useNavigate();
  const { token } = useSelector(selectAuth);

  const [fileSend, setFileSend] = useState<FormData>();
  const [file, setFile] = useState<string | ArrayBuffer>();
  const [errText, isErrText] = useState("");
  const [buttonsSend, isButtonsSend] = useState(false);
  const [buttonAdd, isButtonAdd] = useState(true);
  const [preloader, isPreloader] = useState(false);
  const [fileLoading, isFileLoading] = useState(false);

  const id = localStorage.getItem("id-product");

  useEffect(() => {
    if (!id) {
      navigate("/add-product");
    }
    return () => {
      localStorage.removeItem("id-product");
    };
  }, []);

  function sendFoto(
    file: FormData | undefined,
    token: string | null,
    id: string | null
  ): void {
    isPreloader(true);
    if (!file) {
      isPreloader(false);
      isErrText("выберите файл");
      setTimeout(() => isErrText(""), 1200);
      return;
    }
    if (file && token && id) {
      product
        .addFoto(file, token, id)
        .then((res) => {
          navigate("/add-product");
        })
        .catch((err) => {
          isErrText(`на сервере произошла ошибка: "${err.message}"`);
          setTimeout(() => isErrText(""), 1200);
        })
        .finally(() => isPreloader(false));
    } else {
      isPreloader(false);
      isErrText("при отправки файла произошла ошибка");
      setTimeout(() => isErrText(""), 1200);
    }
  }

  return (
    <div className="foto-add-box">
      <FotoAdd
        fileLoading={fileLoading}
        isFileLoading={isFileLoading}
        buttonAdd={buttonAdd}
        isButtonAdd={isButtonAdd}
        isButtonsSend={isButtonsSend}
        isErrText={isErrText}
        file={file}
        setFile={setFile}
        setFileSend={setFileSend}
        textButton="Выбрать фото"
      >
        <ButtonsNavigation text="выбрать позже" page="/add-product" />
      </FotoAdd>
      {buttonsSend && (
        <div className="foto-add-box__box-button">
          <Button
            buttonClick={() => sendFoto(fileSend, token, id)}
            text="Отправить"
          />
          <Button
            buttonClick={() => {
              setFile("");
              isButtonAdd(true);
              isButtonsSend(false);
            }}
            text="Отмена"
          />
        </div>
      )}
      <ErrorSpan text={errText} />
      {preloader && <ModulePreloader text="Загрузка..." />}
    </div>
  );
}
