import { useNavigate } from "react-router-dom";

export default function ButtonsNavigation({
  page,
  text,
}: {
  page: string;
  text: string;
}) {
  const navigate = useNavigate();
  return (
    <button  onClick={() => navigate(page)} className="button-nav">
      {text}
    </button>
  );
}
