import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { CardProduct, SendingRequestId } from "../types/typeProduts";
import {
  selectProduct,
  setArrProduct,
  setSearchProduct,
} from "../redax/slices/productSlice";
import { product } from "../utils/ProductApi";

import {
  ErrorBox,
  ModulePreloader,
  Search,
  ErrorSpan,
  Card,
  ButtonsNavigation,
} from "./index";

export default function Product() {
  const windowHeight: number = window.innerHeight - 20;
  const dispatch = useDispatch();
  const { sortProduct, arrProduct } = useSelector(selectProduct);

  const [valueSearch, isValueSeartch] = useState("");
  const [preloader, isPreloader] = useState(true);
  const [preloaderDelete, isPreloaderDelete] = useState(false);
  const [textErrServer, isTextErrServer] = useState("Товар отсутствует");
  const [textErrDelete, isTextErrDelete] = useState("");
  const [errDelete, isErrDelete] = useState(false);

  let search: boolean = true;
  const searchProduct = (
    value: string,
    arr: CardProduct[],
    time: number
  ): void => {
    if (search) {
      setTimeout(() => {
        const newArr = arr.filter((elem) =>
          elem.title.toLowerCase().includes(value.toLowerCase())
        );
        dispatch(setSearchProduct(newArr));
        search = true;
      }, time);
      search = false;
    }
  };

  const getProductAll = () => {
    product
      .getAllProduct()
      .then((res) => {
        //console.log(res);
        dispatch(setArrProduct(res.reverse()));
      })
      .catch((err) => {
        console.log(err);
        isTextErrServer(
          `При загрузке товара на сервере произошла ошибка (${err.message})`
        );
      })
      .finally(() => isPreloader(false));
  };

  useEffect(() => {
    getProductAll();
  }, []);

  const deleteProduct = ({ id, token }: SendingRequestId) => {
    isPreloaderDelete(true);
    product
      .deleteProduct({ id, token })
      .then((res) => {
        // console.log(res);
        isPreloader(true);
        getProductAll();
      })
      .catch((err) => {
        isTextErrDelete(err.message);
        isErrDelete(true);
        setTimeout(() => isErrDelete(false), 1500);
        //console.log(err);
      })
      .finally(() => isPreloaderDelete(false));
  };

  return (
    <section style={{ height: windowHeight }} className="product">
      <div className="product__header">
        <input
          value={valueSearch}
          onChange={(evt) => {
            isValueSeartch(evt.target.value);
            searchProduct(evt.target.value, arrProduct, 800);
          }}
          className="product__search"
          placeholder="Поиск..."
        />
        <h2 className="product_title">Каталог</h2>
        <ButtonsNavigation page="/" text="назад" />
      </div>

      <ErrorBox>
        {errDelete && (
          <ErrorSpan
            text={`При удалении товара произошла ошибка: ${textErrDelete}`}
          />
        )}
      </ErrorBox>

      <div className="product__conteiner-card">
        {preloader ? (
          <Search />
        ) : sortProduct.length ? (
          sortProduct?.map((obj, i) => (
            <Card {...obj} key={obj._id + i} deleteProduct={deleteProduct} />
          ))
        ) : (
          <ErrorSpan text={textErrServer} />
        )}
      </div>
      {preloaderDelete && <ModulePreloader text="Удаление..." />}
    </section>
  );
}
