import React, { useState } from "react";
import Form from "./Form";
import ErrorSpan from "./ErrorSpan";
import { Input } from "../types/tupes";
type FormAuthorizationProps = {
  handleSubmit: (evt: React.FormEvent<HTMLFormElement>) => any;
  valueLogin: string;
  setValueLogin: React.Dispatch<React.SetStateAction<string>>;
  valuePassword: string;
  setValuePassword: React.Dispatch<React.SetStateAction<string>>;
  textButton: string;
  autoCompletePassword: string;
};

export default function FormAuth({
  handleSubmit,
  valueLogin,
  setValueLogin,
  valuePassword,
  setValuePassword,
  textButton,
  autoCompletePassword,
}: FormAuthorizationProps) {
  const [loginErr, setLoginErr] = useState("");
  const [passwordErr, setPasswordErr] = useState("");
  const [buttonInalid, setButtonValid] = useState(true);
  const [inputInalid, setInputInalid] = useState<Input>();

  const checkValue = (evt: React.ChangeEvent<HTMLInputElement>): string => {
    if (!evt.target.checkValidity()) {
      setInputInalid({ ...inputInalid, [evt.target.name]: true });
      return `минимальное количество символов без пробелов ${evt.target.minLength}`;
    } else {
      setInputInalid({ [evt.target.name]: false });
      return "";
    }
  };

  const checkForm = (evt: React.ChangeEvent<HTMLInputElement>): boolean => {
    const validity = evt.target.closest("form")?.checkValidity();

    if (validity !== undefined) {
      return !validity;
    } else {
      return true;
    }
  };

  return (
    <Form
      handleSubmit={handleSubmit}
      disabledButton={buttonInalid}
      textButton={textButton}
    >
      <input
        pattern="^((?!\s).)*$"
        className={`input ${
          !inputInalid?.login ? "input_state_valid" : "input_state_invalid"
        }`}
        value={valueLogin}
        onChange={(evt) => {
          setButtonValid(checkForm(evt));
          setLoginErr(checkValue(evt));
          setValueLogin(evt.target.value);
        }}
        name="login"
        placeholder="login"
        maxLength={30}
        minLength={2}
        required
        autoComplete={"username"}
      ></input>
      <ErrorSpan text={loginErr} />
      <input
        pattern="^((?!\s).)*$"
        className={`input ${
          !inputInalid?.password ? "input_state_valid" : "input_state_invalid"
        }`}
        value={valuePassword}
        onChange={(evt) => {
          setButtonValid(checkForm(evt));
          setPasswordErr(checkValue(evt));
          setValuePassword(evt.target.value);
        }}
        type="password"
        name="password"
        placeholder="password"
        minLength={8}
        required
        autoComplete={autoCompletePassword}
      ></input>
      <ErrorSpan text={passwordErr} />
    </Form>
  );
}
