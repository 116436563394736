import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { RootState } from "../store";

type PopUpWindowState = {
  stateWindow: boolean;
};

const initialState: PopUpWindowState = {
  stateWindow: false,
};

const popUpWindowSlice = createSlice({
  name: "popUpWindow",
  initialState,
  reducers: {
    killAllStatePopUpWindow(state) {
      state.stateWindow = false;
    },
    isPopUpWindow(state, action: PayloadAction<boolean>) {
      state.stateWindow = action.payload;
    },
  },
});

export const selectPopUpWindow = (state: RootState) => state.popUpWindow;

export const { killAllStatePopUpWindow, isPopUpWindow } =
  popUpWindowSlice.actions;
export default popUpWindowSlice.reducer;
