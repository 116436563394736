import { configureStore } from "@reduxjs/toolkit";
import { useDispatch } from "react-redux";
import product from "./slices/productSlice";
import auth from "./slices/authSlice";
import popUpWindow from "./slices/popUpWindowSlice";

export const store = configureStore({
  reducer: {
    product,
    auth,
    popUpWindow,
  },
});

export type RootState = ReturnType<typeof store.getState>;

export type AppDispatch = typeof store.dispatch;
export const useAppDispatch: () => AppDispatch = useDispatch;
