import { Dispatch, SetStateAction, useState } from "react";
import { CardProductProps } from "../types/typeProduts";
import { URL_SERVER } from "../utils/Constants";
import { useSelector } from "react-redux";
import { selectAuth } from "../redax/slices/authSlice";
import { useNavigate } from "react-router-dom";

import { ModuleQuote, ModuleBigFoto, ModulConfirmation } from "./index";
import Clue from "./Clue";

export default function Card({
  foto,
  title,
  price,
  discription,
  _id,
  deleteProduct,
}: CardProductProps) {
  const navigate = useNavigate();
  const [bigFoto, isBigFoto] = useState(false);
  const [discriptionAll, isDiscriptionAll] = useState(false);
  const [confirmation, isConfirmation] = useState(false);

  const { token } = useSelector(selectAuth);

  const discriptionLength = discription.length > 50;

  const cutText = (text: string, num: number): string => {
    if (text.length < num) {
      return text;
    }
    return `${text.slice(0, num)}...`;
  };

  const onFotoKeydown = (evt: KeyboardEvent) => {
    if (evt.code === "Enter") {
      isBigFoto(true);
    }
  };

  const onDiscriptionKeydown = (evt: KeyboardEvent) => {
    if (evt.code === "Enter") {
      isDiscriptionAll(true);
    }
  };

  return (
    <div className="card">
      <div className="card__foto-conteiner">
        {foto ? (
          <Clue textClue="Фото в полном размере ">
            <img
              onFocus={(evt) => {
                evt.target.addEventListener("keydown", onFotoKeydown);
              }}
              onBlur={(evt) => {
                evt.target.removeEventListener("keydown", onFotoKeydown);
              }}
              tabIndex={foto ? 0 : -1}
              className={`card__foto-default ${foto ? `card__foto` : ""}`}
              onClick={() => foto && isBigFoto(true)}
              src={foto ? `${URL_SERVER}/${foto}` : "../images/images.png"}
              alt="фото товара"
            />
          </Clue>
        ) : (
          <img
            className={"card__foto-default"}
            src={"../images/images.png"}
            alt="перечеркнутое фото"
          />
        )}
      </div>
      <h2 className="card__title">{title}</h2>
      <Clue active={discriptionLength} textClue={"Полное описание товара"}>
        <h3
          onFocus={(evt) => {
            evt.target.addEventListener("keydown", onDiscriptionKeydown);
          }}
          onBlur={(evt) =>
            evt.target.removeEventListener("keydown", onDiscriptionKeydown)
          }
          tabIndex={discriptionLength ? 0 : -1}
          onClick={() => discriptionLength && isDiscriptionAll(true)}
          className={`card__discription ${
            discriptionLength ? "" : "card__discription_state_not-active"
          }`}
        >
          {cutText(discription, 50)}
        </h3>
      </Clue>

      <span className="card__price">{`${price} руб.`}</span>
      <div className="card__box-edit">
        <button
          onClick={() => {
            localStorage.setItem("id-product", _id);
            navigate("/edit-product");
          }}
          className="card__button card__edit"
          title="редактировать"
        ></button>
        <button
          onClick={() => isConfirmation(true)}
          className="card__button card__delete"
          title="удалить"
        ></button>
      </div>
      {bigFoto && (
        <ModuleBigFoto
          isShowBigAvatar={() => isBigFoto(false)}
          urlFoto={`${URL_SERVER}/${foto}`}
        />
      )}
      {discriptionAll && (
        <ModuleQuote clickOverly={isDiscriptionAll} text={discription} />
      )}
      {confirmation && (
        <ModulConfirmation
          text="Удалить?"
          confirm={() => {
            deleteProduct({ id: _id, token });
          }}
          close={() => isConfirmation(false)}
        />
      )}
    </div>
  );
}
