type BottonSubmitProps = {
  text: string;
  disabled?: boolean;
};

export default function BottonSubmit({
  text,
  disabled = false,
}: BottonSubmitProps) {
  return (
    <button disabled={disabled} className="botton-submit" type="submit">
      {text}
    </button>
  );
}
