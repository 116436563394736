import { Check, ParamsProduct } from '../types/typeProduts';
import { URL_SERVER } from './Constants';

export type ProductApi = {
  baseUrl: string;
};

class Product {
  readonly baseUrl: string;
  constructor({ baseUrl }: ProductApi) {
    this.baseUrl = baseUrl;
  }

  public getAllProduct() {
    return fetch(`${URL_SERVER}/auth-not/product`, {
      method: 'get',
      headers: {
        'content-type': 'application/json',
      },
    }).then(this._checkResponse);
  }

  public getIdProduct(token: string, id: string) {
    return fetch(`${this.baseUrl}/${id}`, {
      method: 'get',
      headers: {
        authorization: `Bearer ${localStorage.getItem('token') || token}`,
        'content-type': 'application/json',
      },
    }).then(this._checkResponse);
  }

  public addProduct(obj: ParamsProduct): Promise<any> {
    return fetch(`${this.baseUrl}/create`, {
      method: 'POST',
      headers: {
        authorization: `Bearer ${localStorage.getItem('token') || obj.token}`,
        'content-type': 'application/json',
      },
      body: JSON.stringify({
        title: obj.title,
        price: obj.price,
        discription: obj.discription,
        gender: obj.gender,
      }),
    }).then(this._checkResponse);
  }

  public addFoto(foto: FormData, token: string, id: string): Promise<any> {
    return fetch(`${this.baseUrl}/add-foto/${id}`, {
      method: 'PATCH',
      headers: {
        authorization: `Bearer ${localStorage.getItem('token') || token}`,
      },
      body: foto,
    }).then(this._checkResponse);
  }

  public resetFoto(token: string, id: string): Promise<any> {
    return fetch(`${this.baseUrl}/foto-reset/${id}`, {
      method: 'PATCH',
      headers: {
        authorization: `Bearer ${localStorage.getItem('token') || token}`,
      },
    }).then(this._checkResponse);
  }

  public deleteProduct({ id, token }: { id: string; token: string | null }) {
    return fetch(`${this.baseUrl}/${id}`, {
      method: 'DELETE',
      headers: {
        authorization: `Bearer ${localStorage.getItem('token') || token}`,
      },
    }).then(this._checkResponse);
  }

  public deleteFotoProduct({
    id,
    token,
  }: {
    id: string;
    token: string | null;
  }) {
    return fetch(`${this.baseUrl}/foto/${id}`, {
      method: 'PATCH',
      headers: {
        authorization: `Bearer ${localStorage.getItem('token') || token}`,
      },
    }).then(this._checkResponse);
  }

  public patchTitle({
    id,
    token,
    title,
  }: {
    id: string;
    token: string;
    title: string;
  }) {
    return fetch(`${this.baseUrl}/title/${id}`, {
      method: 'PATCH',
      headers: {
        authorization: `Bearer ${localStorage.getItem('token') || token}`,
        'content-type': 'application/json',
      },
      body: JSON.stringify({
        title,
      }),
    }).then(this._checkResponse);
  }

  public patchDiscription({
    id,
    token,
    discription,
  }: {
    id: string;
    token: string;
    discription: string;
  }) {
    return fetch(`${this.baseUrl}/discription/${id}`, {
      method: 'PATCH',
      headers: {
        authorization: `Bearer ${localStorage.getItem('token') || token}`,
        'content-type': 'application/json',
      },
      body: JSON.stringify({
        discription,
      }),
    }).then(this._checkResponse);
  }

  public patchPrice({
    id,
    token,
    price,
  }: {
    id: string;
    token: string;
    price: string;
  }) {
    return fetch(`${this.baseUrl}/price/${id}`, {
      method: 'PATCH',
      headers: {
        authorization: `Bearer ${localStorage.getItem('token') || token}`,
        'content-type': 'application/json',
      },
      body: JSON.stringify({
        price,
      }),
    }).then(this._checkResponse);
  }

  public patchGender({
    id,
    token,
    gender,
  }: {
    id: string;
    token: string;
    gender: Check[];
  }) {
    return fetch(`${this.baseUrl}/gender/${id}`, {
      method: 'PATCH',
      headers: {
        authorization: `Bearer ${localStorage.getItem('token') || token}`,
        'content-type': 'application/json',
      },
      body: JSON.stringify({
        gender,
      }),
    }).then(this._checkResponse);
  }

  private _checkResponse = (res: Response) => {
    if (res.ok) {
      return res.json();
    }
    return res.text().then((err) => Promise.reject(JSON.parse(err)));
  };
}

const product = new Product({
  baseUrl: `${URL_SERVER}/product`,
});

export { product };
