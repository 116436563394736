import React, { useState } from "react";
import { auth } from "../utils/Auth";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { setToken } from "../redax/slices/authSlice";

import {
  ErrorSpan,
  FormAuth,
  ButtonsNavigation,
  ModulePreloader,
} from "../components/index";

export default function FormAuthorization() {
  const dispath = useDispatch();
  const navigate = useNavigate();
  const [valueLogin, setValueLogin] = useState("");
  const [valuePassword, setValuePassword] = useState("");
  const [preloader, setPreloader] = useState(false);
  const [errText, setErrText] = useState("");

  const handleSubmit = (evt: React.FormEvent<HTMLFormElement>): void => {
    setPreloader(true);
    evt.preventDefault();
    auth
      .addAdmin(valueLogin, valuePassword)
      .then((res: { _id: string }) => {
        auth
          .loginUser(valueLogin, valuePassword)
          .then((res: { token: string }) => {
            localStorage.setItem("token", res.token);
            dispath(setToken(res.token));
            navigate("/");
          })
          .catch((err) => {
            setErrText(err.message);
            setTimeout(() => setErrText(""), 1200);
          })
          .finally(() => setPreloader(false));
      })
      .catch((err) => {
        setPreloader(false);
        setErrText(err.message);
        setTimeout(() => setErrText(""), 1200);
      });
  };

  return (
    <div className="entry">
      <h1 className="entry__title">Регистрация</h1>
      <FormAuth
        handleSubmit={handleSubmit}
        valueLogin={valueLogin}
        setValueLogin={setValueLogin}
        valuePassword={valuePassword}
        setValuePassword={setValuePassword}
        textButton={"Зарегистрироваться"}
        autoCompletePassword={"new-password"}
      />
      <ErrorSpan text={errText} />
      <div className="entry__button">
        <ButtonsNavigation text="Войти" page="/auth" />
      </div>
      {preloader && <ModulePreloader text="Регистрация..." />}
    </div>
  );
}
