import { useDispatch } from 'react-redux';

import { setToken } from '../redax/slices/authSlice';
import Button from './buttons/Button';

export default function Header() {
  const dispatch = useDispatch();
  return (
    <div className="header">
      <Button
        buttonClick={() => {
          localStorage.clear();
          dispatch(setToken(''));
        }}
        text="Выйти"
      />
    </div>
  );
}
