import React, { useRef, useState } from "react";
import Button from "./buttons/Button";
import { URL_SERVER } from "../utils/Constants";

type FotoAddProps = {
  setFileSend: React.Dispatch<React.SetStateAction<FormData | undefined>>;
  setFile: React.Dispatch<
    React.SetStateAction<string | ArrayBuffer | undefined>
  >;
  file: string | ArrayBuffer | undefined;
  isErrText: React.Dispatch<React.SetStateAction<string>>;
  isButtonsSend: React.Dispatch<React.SetStateAction<boolean>>;
  buttonAdd: boolean;
  isButtonAdd: React.Dispatch<React.SetStateAction<boolean>>;
  children?: JSX.Element[] | JSX.Element;
  textButton: string;
  fileLoading: boolean;
  isFileLoading: React.Dispatch<React.SetStateAction<boolean>>;
};

export default function FotoAdd({
  fileLoading,
  isFileLoading,
  setFileSend,
  setFile,
  file,
  isErrText,
  isButtonsSend,
  buttonAdd,
  isButtonAdd,
  children,
  textButton,
}: FotoAddProps) {
  const refInputFile = useRef<HTMLInputElement>(null);
  const addFoto = (evt: React.ChangeEvent<HTMLInputElement>): void => {
    isFileLoading(true);
    const file = evt.target.files ? evt.target.files[0] : false;
    file && setFileToBase(file);
  };

  const setFileToBase = (file: File): void => {
    try {
      const render = new FileReader();
      render.readAsDataURL(file);
      render.onloadend = () => {
        if (!render.result) return;
        const foto = new FormData();
        foto.append("foto", file);
        setFileSend(foto);
        setFile(render.result);
        isButtonsSend(true);
        isButtonAdd(false);
      };
    } catch (error) {
      //console.log('при загрузке файла произошла ошибка');
      isErrText("при загрузке файла произошла ошибка");
      setTimeout(() => isErrText(""), 1200);
    }
  };

  const selectFoto = (): void => {
    refInputFile.current?.click();
  };

  return (
    <div className="foto-add">
      <div className="foto-add__conteiner-foto">
        {
          <img
            className="foto-add__foto"
            src={
              file
                ? fileLoading
                  ? String(file)
                  : `${URL_SERVER}/${file}`
                : "../images/images.png"
            }
            alt="аватар"
          />
        }
        {buttonAdd && (
          <div className="foto-add__box-button">
            <Button
              buttonClick={() => {
                selectFoto();
              }}
              text={textButton}
            />
            {children}
          </div>
        )}
      </div>
      <input
        ref={refInputFile}
        className="foto-add__input"
        type="file"
        onChange={(evt) => {
          addFoto(evt);
        }}
        accept="image/*"
      ></input>
    </div>
  );
}
