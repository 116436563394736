import Header from "../components/Header";
import MenuNavigate from "../components/MenuNavigate";
export default function Home() {
  return (
    <div className="home">
      <header>
        <Header />
      </header>
      <main>
        <section>
          <MenuNavigate />
        </section>
      </main>
    </div>
  );
}
